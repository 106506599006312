import React, { useEffect, useRef, useState } from 'react';
import { MenuToolbar } from '@/routes';
import { Link } from 'react-router-dom';

import { FaPlus, FaTimes } from 'react-icons/fa';
import { AiOutlineAudit, AiOutlineFileProtect } from 'react-icons/ai';
import { RiUserStarFill } from 'react-icons/ri';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Dropzone, Card, Swal, SelectSearch, CurrencyInput, checkError, PercentageInput } from '@/components/UI';
import { usePatch, handleDiff, compact } from '@/components/Utils';
import './BienCommercial.styles.scss';
import { connect } from 'react-redux';
import { Currency } from '../../../../../components/UI';
import { useGet } from '../../../../../components/Utils';

const BienCommercial = ({ bienDetail, action, refetch, roleSelected, privilegeSelected, user }) => {
    const [temporary, setTemporary] = React.useState(null);
    const [status, setStatus] = React.useState(null);
    const hiddenRef = useRef();

    const { setValue, getValues, register, handleSubmit, clearErrors, watch, control, formState: { errors, dirtyFields } } = useForm({
        defaultValues: {
            commercial: {},
            ...bienDetail,
        },
        mode: "onSubmit",
        reValidateMode: "onChange"
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "negociateurs",
    });

    watch("commercial");
    watch("negociateurs");
    watch("etat");

    const negociateurs = getValues("negociateurs");
    const commercial = getValues("commercial");
    const etat = getValues("etat");

    const [cabinate, setCabinate] = useState(commercial.interCabinet);

    const { data: societe } = useGet(`/societes/agence/${bienDetail.agenceId}`);
    
    const { data: agenceCabinet } = useGet(`/agences/${commercial?.agenceCabinet}`);

    const [MontantComissionHt, setMontantComissionHt] = React.useState((commercial.montantCommission ?? 0) / 1.2);

    const [updateBien, { fusion }] = usePatch(`/bien_immobiliers/${bienDetail ? bienDetail.id : null}`, {
        'Content-Type': 'application/merge-patch+json'
    });

    const handleMontantComissionChange = (e) => {
        const ttc = parseFloat(e);
        const ht = ttc / 1.2;
        setMontantComissionHt(ht);
        setValue('commercial.montantCommission', e, { shouldDirty: true })

    }

    const onSubmit = ({ ...rest }) => {
        let compactDirtyFields = compact(dirtyFields);

        let updatedFields = handleDiff(compactDirtyFields, rest);
        let commercialUpdates = {};

        if (rest.promesseScan === null && updatedFields.promesseScan === undefined) {
            updatedFields.promesseScan = null;
            // updatedFields.mandatScan = null;
        }

        // Prendre en compte la mise à jour du document imbriqué commercial
        if (dirtyFields?.commercial) {
            updatedFields["commercial"] = {};

            rest.commercial.percentageRedevance = societe?.percentageRedevance;
            rest.commercial.montantRedevance = rest.commercial.montantCommission * societe?.percentageRedevance / 100;
            commercialUpdates = handleDiff(dirtyFields?.commercial, rest.commercial);
            
            const fieldUpdates = [
                'provenance',
                'attestation',
                'signaturePrevision',
                'signatureReelle',
                'paiementNotaire',
                'commentaire',
                'prixNet',
                'montantCommission',
                'percentageCommission',
                'percentageRedevance',
                'montantRedevance',
                'interCabinet',
                'agenceCabinet',
                'dateSousOffre',
                'dateSousPromesse',
                'dateActeDefinitif',
                'dateArchive',
                'dateLocation',
                'dateLoue',
            ];

            
            fieldUpdates.map(field => {

                if (rest.commercial.attestation === null && updatedFields.commercial.attestation === undefined) {
                    updatedFields.commercial.attestation = null;
                }

                if (commercialUpdates.hasOwnProperty(field) && commercialUpdates[field]) {
                    if (field === 'provenance') {
                        updatedFields["commercial"][field] = `/api/provenances/${commercialUpdates?.provenance.id}`;
                    } else if(field === 'agenceCabinet'){
                        updatedFields["commercial"][field] = commercialUpdates?.agenceCabinet.id;
                    } else if(field === 'interCabinet'){
                        updatedFields["commercial"][field] = commercialUpdates?.interCabinet === 'true' ? true : false;
                        
                        if (commercialUpdates?.interCabinet === 'false') {
                            updatedFields["commercial"]['agenceCabinet'] = null;
                        }
                    }
                    else {
                        updatedFields["commercial"][field] = commercialUpdates[field];
                    }

                    if (field === "montantCommission") {
                        updatedFields["commercial"]["percentageRedevance"] = String(societe?.percentageRedevance);
                        updatedFields["commercial"]["montantRedevance"] = String((commercialUpdates[field] / 1.2) * societe?.percentageRedevance / 100);
                    }
                }

                const fieldsToConvert = [
                    "prixNet",
                    "montantCommission",
                    "percentageCommission",
                    "percentageRedevance",
                    "montantRedevance"
                ];

                fieldsToConvert.forEach(field => {
                    if (updatedFields["commercial"][field] !== undefined) {
                        updatedFields["commercial"][field] = String(updatedFields["commercial"][field]);
                    }
                });

                return field;
            })
        }
        
        // Mis à jour de la liste des négociateurs associé au bien
        if (dirtyFields?.negociateurs) {
            updatedFields["negociateurs"] = rest.negociateurs;
        }

        if (updatedFields?.mandatType) {
            updatedFields["mandatType"] = parseInt(updatedFields?.mandatType);
        }

        if (updatedFields?.etat) {
            updatedFields["etat"] = parseInt(updatedFields?.etat);
        }

        if (updatedFields?.commercial) {
            updatedFields.commercial.author = "/api/users/"+ user.user_id;
        }


        updateBien(updatedFields).then(() => {
            refetch();
            Swal.fire({
                title: 'Bien immobilier mis à jour avec succès',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        })
    }

    const hasUpdates = Object.keys(dirtyFields).length > 0;

    const addElement = (clearSelect) => {
        if (!temporary) return null;

        // Check if agence already exist
        const exist = negociateurs.find(user => user.userId === temporary.id);


        if (exist === undefined) {
            append({
                nom: temporary.nom,
                prenom: temporary.prenom,
                telephone: temporary.telephone,
                email: temporary.email,
                negociateur: `/api/users/${temporary.id}`,
                userId: temporary.id
            });
            clearSelect();
            setTemporary(null);
        } else {

            Swal.fire({
                title: `<span style="color: red">Le négociateur existe déjà</span>`,
                icon: 'error',
                html: `Le négociateur existe déjà dans la liste`,
                showCloseButton: false,
                showConfirmButton: false,
                timer: 1500
            });
            clearSelect();
            setTemporary(null);
        }
        
    }

    const removeElement = (index) => {
        Swal.fire({
            title: `<span style="color: red">Supprimer le négociateur?</span>`,
            icon: 'warning',
            html: `Voulez-vous réellement supprimer le négociateur de la liste?`,
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Oui',
            confirmButtonColor: 'red',
            cancelButtonText: 'Annuler'
        }).then(result => {
            if (result.isConfirmed) {
                remove(index)
            }
        })
    }

    return (
        <form className="bien-commercial" onSubmit={handleSubmit(onSubmit)}>
            <MenuToolbar
                title={`Modification bien immobilier`}
                subtitle={`Suivi commercialisation #${bienDetail.id}`}
                action={() => 
                <button
                    {...fusion({ disabled: !hasUpdates})}
                    className="btn btn-success btn-sm font-weight-bold mr-2">
                    <FaPlus /> Sauvegarder
                </button>}>
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <li className="breadcrumb-item text-muted">
                        <Link to="/biens/liste" className="text-muted text-hover-primary">Liste des biens immobiliers</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    </li>
                </ul>
            </MenuToolbar>
            
            <div className="row">
                <div className="col-md-6">
                    <Card
                        title={`Suivi de la commercialisation`}
                        titleIcon={<AiOutlineAudit />}
                        titleColor={`#ff793f`}>

                        <div className="mb-4">
                            <label className="form-label fw-bold text-primary" style={{fontSize: '16px'}}>Etat de la commercialisation <span className='text-danger'>*</span></label>
                            <div className="form-inline">
                                <select
                                    {...register('etat', { required: true })}
                                    className="form-select">
                                    <option value="0">---Choisir---</option>
                                    <option value="1">En vente</option>
                                    <option value="4">Sous offre</option>
                                    <option value="2">Sous promesse de vente</option>
                                    <option value="3">Acte définitif signé</option>
                                    <option value="6">En location</option>
                                    <option value="7">Loué</option>
                                    <option value="5">Archivé</option>
                                </select>
                            </div>
                        </div>

                        {
                            etat > 1 ? (

                                <div className="mb-4">
                                    <label className="form-label fw-bold" style={{fontSize: '16px'}}>
                                        Date de basculement d'état <span className='text-danger'>*</span>
                                    </label>
                                    <div className="form-inline flex">
                                        <div>

                                            {
                                                parseInt(etat) === 4 ? (
                                                    <input id={etat} type="date"
                                                        {...register('commercial.dateSousOffre', { required: true })}
                                                        className="form-control"/>
                                                ) : null
                                            }

                                            {
                                                parseInt(etat) === 3 ? (
                                                    <input id={etat} type="date"
                                                        {...register('commercial.dateActeDefinitif', { required: true })}
                                                        className="form-control"/>
                                                ) : null
                                            }

                                            {
                                                parseInt(etat) === 2 ? (
                                                    <input id={etat} type="date"
                                                        {...register('commercial.dateSousPromesse', { required: true })}
                                                        className="form-control"/>
                                                ) : null
                                            } 
                                            
                                            {
                                                parseInt(etat) === 5 ? (
                                                    <input id={etat} type="date"
                                                        {...register('commercial.dateArchive', { required: true })}
                                                        className="form-control"/>
                                                ) : null
                                            }

                                            {
                                                parseInt(etat) === 6 ? (
                                                    <input id={etat} type="date"
                                                        {...register('commercial.dateLocation', { required: true })}
                                                        className="form-control"/>
                                                ) : null
                                            }

                                            {
                                                parseInt(etat) === 7 ? (
                                                    <input id={etat} type="date"
                                                        {...register('commercial.dateLoue', { required: true })}
                                                        className="form-control"/>
                                                ) : null
                                            }

                                        </div>
                                    </div>
                                </div>

                            ) : null
                        }

                        <div className="mb-4">
                            <label className="form-label fw-bold text-primary" style={{fontSize: '16px'}}>Type de mandat <span className='text-danger'>*</span></label>
                            <div className="form-inline flex">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        value={1}
                                        id="mandat-simple"
                                        type="radio"
                                        defaultChecked={bienDetail.mandatType === 1}
                                        {...register('mandatType', { required: true })}/>

                                    <label className="form-check-label" htmlFor="mandat-simple">
                                        Mandat simple
                                    </label>
                                </div>

                                <div className="form-check ms-4">
                                    <input
                                        className="form-check-input"
                                        value={2}
                                        id="mandat-exclusif"
                                        type="radio"
                                        defaultChecked={bienDetail.mandatType === 1}
                                        {...register('mandatType', { required: true })}/>

                                    <label className="form-check-label" htmlFor="mandat-exclusif">
                                        Mandat exclusif
                                    </label>
                                </div>
                            </div>
                        </div>

                        { parseInt(etat) >= 2 && parseInt(etat) !== 5 ? (
                            <>
                                <div className="mt-8 px-8">
                                    <div className="mb-6 col-md-12">
                                        <label className="form-label fw-bold" style={{fontSize: '16px'}}>
                                            Prix net (sans FAI) signé <span className='text-danger'>*</span>
                                        </label>
                                        <div className="form-inline flex">
                                            <div>
                                                <Controller
                                                    name="commercial.prixNet"
                                                    rules={{ required: true }}
                                                    defaultValue={0}
                                                    control={control}
                                                    render={({ field }) => {
                                                        return <CurrencyInput 
                                                            {...field}
                                                            className={`form-control`}
                                                            defaultValue={commercial.prixNet ?? 0}
                                                            placeholder={`Prix net (sans FAI) signé`}/>
                                                    }}/>
                                            </div>
                                        </div>
                                        { checkError('commercial.prixNet', errors, `Vous devez saisir le prix net`) }
                                    </div>
                                    <div className="mb-6 col-md-12">
                                        <label className="form-label fw-bold" style={{fontSize: '16px'}}>
                                            Montant de la commission signée TTC <span className='text-danger'>*</span>
                                        </label>
                                        <div className="form-inline flex">
                                            <div>
                                                <Controller
                                                    name="commercial.montantCommission"
                                                    rules={{ required: true }}
                                                    defaultValue={0}
                                                    control={control}
                                                    render={({ field }) => {
                                                        return <CurrencyInput 
                                                            {...field}
                                                            className={`form-control`}
                                                            onChange={handleMontantComissionChange}
                                                            defaultValue={commercial.montantCommission ?? 0}
                                                            placeholder={`Montant de la commission signée`}/>
                                                    }}/>
                                            </div>
                                        </div>
                                        { checkError('commercial.montantCommission', errors, `Vous devez saisir le montant de la commission`) }
                                    </div>

                                    <div className="row mb-5">
                                        <div className={societe?.franchise && (roleSelected === 2 || roleSelected === 1) ? "col-md-6 form-group" : "col-md-12 form-group"}>
                                            <label className="form-label fw-bold" style={{fontSize: '16px'}}>
                                                Montant de la commission signée en HT:
                                            </label>
                                            <div className="input-group">
                                                <div className='ms-4' style={{fontSize: '16px'}}>
                                                    <Currency value={MontantComissionHt}/>
                                                </div>
                                            </div>
                                        </div>

                                        
                                        {
                                            societe?.franchise && (roleSelected === 2 || roleSelected === 1)? (
                                                <div className="col-md-6 form-group">
                                                    <label className="form-label fw-bold" style={{fontSize: '16px'}}>
                                                        Montant à reverser en tant que franchise en HT:
                                                    </label>
                                                    <div className="input-group">
                                                        <div className='ms-4' style={{fontSize: '16px'}}>
                                                            <Currency value={MontantComissionHt * societe?.percentageRedevance / 100}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null
                                        }

                                    </div>

                                    <div className="mb-6 col-md-12">
                                        <label className="form-label fw-bold" style={{fontSize: '16px'}}>
                                            % commission <span className='text-danger'>*</span>
                                        </label>
                                        <div className="form-inline flex">
                                            <div>
                                                <Controller
                                                    name="commercial.percentageCommission"
                                                    rules={{ required: true }}
                                                    defaultValue={0}
                                                    control={control}
                                                    render={({ field }) => {
                                                        return <PercentageInput 
                                                            {...field}
                                                            className={`form-control`}
                                                            defaultValue={commercial.percentageCommission ?? 0}
                                                            placeholder={`% commission`}/>
                                                    }}/>
                                            </div>
                                        </div>
                                        { checkError('commercial.percentageCommission', errors, `Vous devez saisir le pourcentage de la commission`) }
                                    </div>
                                </div>
                                <div className="mb-4 px-8 py-6 mandat-scan">
                                    <div className="mandat-wrapper">
                                        <div className="col-md-12 mb-6">
                                            <label className="form-label fw-bold" style={{fontSize: '16px'}}>
                                                <AiOutlineFileProtect className="me-2" style={{fontSize: '28px'}}/>
                                                Promesse signée scannée
                                            </label>
                                            <Dropzone
                                                className="small-dropzone"
                                                action={action}
                                                defaultValue={bienDetail && bienDetail?.promesseScan ? bienDetail.promesseScan : null}
                                                onUploaded={({ id, filePath }) => {
                                                    if (filePath) {
                                                        // setValue('mandatScan', filePath, { shouldDirty: true });
                                                        setValue('promesseScan', `/api/file_uploads/${id}`, { shouldDirty: true })
                                                    } else {
                                                        // setValue('mandatScan', null, { shouldDirty: true });
                                                        setValue('promesseScan', null, { shouldDirty: true })
                                                    }
                                                }}/>
                                        </div>

                                        { parseInt(etat) !== 4 ? (

                                            <div className="mb-4">
                                                <label className="form-label fw-bold" style={{fontSize: '16px'}}>
                                                    Date de signature définitive prévisionnelle <span className='text-danger'>*</span>
                                                </label>
                                                <div className="form-inline flex">
                                                    <div>
                                                        <input type="date"
                                                            {...register('commercial.signaturePrevision', { required: true })}
                                                            className="form-control"/>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null }
                                    </div>
                                </div>
                            </>
                        ) : null }
                        
                        { parseInt(etat) === 3 ? (
                            <div className="mb-4 px-8 py-6 attestation-scan">
                                <div className="attestation-wrapper">
                                    <div className="col-md-12 mb-6">
                                        <label className="form-label fw-bold" style={{fontSize: '16px'}}>
                                            <AiOutlineAudit className="me-2" style={{fontSize: '28px'}}/> 
                                            Attestation de vente scannée
                                        </label>
                                        <Dropzone
                                            className="small-dropzone"
                                            action={action}
                                            labelItem={`l'attestation de vente`}
                                            defaultValue={commercial && commercial?.attestation ? commercial.attestation : null}
                                            onUploaded={({ id, filePath }) => {
                                                if (filePath) {
                                                    setValue('commercial.attestation', `/api/file_uploads/${id}`, { shouldDirty: true })
                                                } else {
                                                    setValue('commercial.attestation', null, { shouldDirty: true })
                                                }
                                            }}/>
                                    </div>

                                    <div className="mb-4">
                                        <label className="form-label fw-bold" style={{fontSize: '16px'}}>Date de signature réelle <span className='text-danger'>*</span></label>
                                        <div className="form-inline flex">
                                            <div>
                                                <input type="date"
                                                    {...register('commercial.signatureReelle', { required: true })}
                                                    className="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {roleSelected !== 4 && <div className="mt-8">
                                        <label className="form-check" style={{fontSize: '16px'}}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultChecked={commercial.paiementNotaire === 1}
                                                onChange={function(event) {
                                                    const checked = event.target.checked;
                                                    setValue('commercial.paiementNotaire', checked ? 1 : -1, { shouldDirty: true })
                                                }}/>

                                            <span className="form-check-label fw-bold">Le notaire a payé Moriss</span>
                                        </label>
                                    </div>}
                                </div>
                            </div>
                        ) : null }

                        { parseInt(etat) > 1  && parseInt(etat) !== 5 && parseInt(etat) !== 7 ? (
                            <div className="mb-4 px-8" style={{borderTop: '3px dashed rgba(0, 0, 0, .25)'}}>
                                <div className="attestation-wrapper">
                                    
                                    <div className="mt-8">
                                        <label className="form-check" style={{fontSize: '16px'}}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultChecked={commercial.interCabinet}
                                                onChange={function(event) {
                                                    const checked = event.target.checked;
                                                    setCabinate(checked ? true : false);
                                                    setValue('commercial.interCabinet', checked ? 'true' : 'false', { shouldDirty: true })
                                                }}/>

                                            <span className="form-check-label fw-bold">Inter cabinet</span>
                                        </label>
                                    </div>
                                </div>

                                { cabinate ? 

                                    (

                                        <>
                                            <div className="mt-8">
                                                <label className="form-label fw-bold" style={{fontSize: '16px'}}>Liste des choix d'une agnece concerné par la vente <span className='text-danger'>*</span></label>
                                                <div className="form-inline">
                                                    <SelectSearch
                                                        url={`agences?pagination=false`}
                                                        defaultValue={agenceCabinet}
                                                        onSelect={({ value }) => {
                                                            setValue(`commercial.agenceCabinet`, value, { shouldDirty: true });
                                                            clearErrors(`commercial.agenceCabinet`);
                                                        }}/>


                                                    <input type="hidden" {...register(`commercial.agenceCabinet`, { required: true })} />
                                                </div>
                                                { checkError('commercial.agenceCabinet', errors, `Vous devez choisir une agenceCabinet`) }
                                            </div>
                                                { agenceCabinet?.nom ? 

                                                    (
                                                        <div style={{marginTop: '10px'}}>
                                                                <div className="text-dark fw-bolder fs-5">
                                                                <span style={{textDecoration: 'underline'}}>Agence concerné par la vente</span>: { agenceCabinet?.nom }
                                                                </div>
                                                        </div>

                                                    ) : null }

                                        </>                                       
                                        
                                    ) : null}



                            </div>
                        ) : null }
                    </Card>
                </div>

                <div className="col-md-6">
                    <Card
                        title={`Provenance & négociateurs`}
                        titleIcon={<RiUserStarFill />}
                        titleColor={`#ff793f`}>

                        <div className="mb-4">
                            <label className="form-label fw-bold" style={{fontSize: '16px'}}>Provenance <span className='text-danger'>*</span></label>
                            <div className="form-inline">
                                <SelectSearch
                                    url={`/provenances?pagination=false`}
                                    defaultValue={commercial.provenance}
                                    onSelect={({ value }) => {
                                        setValue(`commercial.provenance`, value, { shouldDirty: true });
                                        clearErrors(`commercial.provenance`);
                                    }}/>


                                <input type="hidden" {...register(`commercial.provenance`, { required: true })} />
                            </div>
                            { checkError('commercial.provenance', errors, `Vous devez choisir une provenance`) }
                        </div>

                        <div className="fs-3 mb-2">Ajoutez un négociateur :</div>
                        <div className="d-flex mb-4">
                            <SelectSearch
                                url={`/users/filter?roleSelected=4`}
                                config={{
                                    field: [`nom`, `prenom`],
                                    label: [`nom`, `prenom`]
                                }}
                                className="flex-auto me-2"
                                onSelect={({ value }) => setTemporary(value)}
                                append={({ clearSelect }) => 
                                    <button
                                        onClick={() => addElement(clearSelect)}
                                        // disabled={temporary === null}
                                        className="btn btn-success"
                                        type="button">
                                        <FaPlus /> Ajouter
                                    </button>
                                }/>
                        </div>
                        <div className="fs-3 mb-2 text-primary">Liste des négociateurs :</div>

                        <div style={{minHeight: '100px'}}>
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                    <tr className="border-0">
                                        <th className="p-0 min-w-150px fw-bold"></th>
                                        <th className="p-0 min-w-50px fw-bold"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { negociateurs && negociateurs.length === 0 ? (
                                        <tr>
                                            <td align="center">
                                                <div className="fs-4 text-center text-muted" colSpan="2">Aucun négociateur n'a été ajouté pour le moment.</div>
                                            </td>
                                        </tr>) : null }  

                                        { fields && fields.map((field, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="text-dark fw-bolder fs-5">
                                                            &bull; <span style={{textDecoration: 'underline'}}>Négociateur { index + 1}</span> : { field.nom } { field.prenom } 
                                                        </div>
                                                        {/* <div className="flex text-dark fw-bolder text-hover-primary fs-5">

                                                            <div className="ms-4" style={{color: 'grey'}}>
                                                                <FaPhone /> { field.telephone } / <AiOutlineMail /> { field.email ?? `N/A` }
                                                            </div>
                                                        </div> */}
                                                    </td>
                                                    <td className="text-end">
                                                        <span className="btn btn-sm btn-icon btn-bg-light btn-danger" onClick={() => removeElement(index)}>
                                                            <FaTimes />
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })}      
                                </tbody>
                            </table>
                        </div>
                        
                        <div className="mb-4">
                            <label className="form-label fw-bold" style={{fontSize: '16px'}}>Commentaire</label>
                            <div className="form-inline">
                                <textarea type="text"
                                    {...register('commercial.commentaire')}
                                    className="form-control"/>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </form>
    )
}

const mapStateToProps = (state) => {
    const { user } = state.AuthReducer;
    const { roleSelected, privilegeSelected } = user;
    return {
        roleSelected,
        privilegeSelected,
        user
    }
}

export default connect(mapStateToProps)(BienCommercial)